import { datadogRum } from '@datadog/browser-rum'
import axios from 'axios'
import { action, computed, decorate, observable, reaction } from 'mobx'
import { v4 as uuid } from 'uuid'
import { NOTIFICATION_TYPES } from '../constants'
import AuthStore from './AuthStore'
import GameStore from './GameStore'
import ScheduleStore from './ScheduleStore'
import SocketStore from './SocketStore'
import swal from 'sweetalert2'
import popUpAlert from '../util/popUpAlert'

const fetch = (options) => axios(options).then((r) => r.data)

export default class AppStore {
  constructor(router) {
    this.router = router
    this.auth = new AuthStore(this)
    this.schedule = new ScheduleStore(this)
    this.game = new GameStore(this)
    this.socket = new SocketStore(this)

    this.initialize()
  }

  // routing

  referrer = '/'

  setReferrer() {
    this.referrer = this.url
  }

  get path() {
    return this.router.location ? this.router.location.pathname : '/'
  }

  get url() {
    return this.router.location
      ? this.router.location.pathname + this.router.location.hash
      : '/'
  }

  get isLoginPage() {
    return /^\/login\/?/.test(this.path)
  }

  get isSchedulePage() {
    return /^\/$/.test(this.path)
  }

  get isGamePage() {
    return /^\/games\/?/.test(this.path)
  }

  get isEventPage() {
    return /^\/events\/?/.test(this.path)
  }

  get isAdminPage() {
    return /^\/admin\/?/.test(this.path)
  }

  get gamePk() {
    let rgx = /\/(?:games|events)\/(\d+)\/?/
    let match = rgx.exec(this.path)

    return match && match[1] ? parseInt(match[1]) : null
  }

  get eventId() {
    let rgx = /\/events\/(\d+)\/?/
    let match = rgx.exec(this.path)

    return match && match[1] ? parseInt(match[1]) : null
  }

  set(key, value) {
    this[key] = value
  }

  notificationType = null
  customMessage = null

  flash(notificationType, seconds, customMessage) {
    this.set('notificationType', notificationType)
    this.set('customMessage', customMessage)

    if (seconds) {
      setTimeout(() => {
        this.set('notificationType', null)
        this.set('customMessage', null)
      }, seconds * 1000)
    }
  }

  // active boss connections

  connections = []

  get connectionMap() {
    return this.connections.reduce((map, connection) => {
      map[connection.gamePk] = connection
      return map
    }, {})
  }

  fetchConnections(skip) {
    let id = uuid()

    if (!skip) {
      this._requests[id] = true
    }

    return fetch({
      url: '/api/schedule/connected',
      timeout: 5000,
    })
      .then(
        action((connections) => {
          const wasConnected = Boolean(this.game.isConnected)
          this.connections.replace(connections)
          this.game.isolatedMode = false
          if (wasConnected && !this.game.isConnected) {
            this.flash(NOTIFICATION_TYPES.SESSION_DISCONNECTED)
          }
        })
      )
      .catch((error) => {
        if (this.info.env !== 'BALLPARK') {
          this.connections.clear()
          if (this.game.isConnected) {
            this.flash(NOTIFICATION_TYPES.CONNECTION_ERROR, 3)
          }
        } else {
          // If we're in the ballpark enter isolated mode and don't boot the operator
          this.game.isolatedMode = true
        }

        console.error(error)
      })
      .finally(
        action(() => {
          delete this._requests[id]

          setTimeout(() => {
            this.fetchConnections(true)
          }, 10 * 1000)
        })
      )
  }

  // app info

  info = {}

  fetchInfo() {
    fetch({
      url: '/api/info',
    })
      .then(
        action((info) => {
          this.info = info
        })
      )
      .catch(
        action((err) => {
          console.error(err)
        })
      )
      .finally(() => {
        setTimeout(() => {
          this.fetchInfo()
        }, 60 * 1000)
      })
  }

  _requests = {}

  get isLoading() {
    return Object.keys(this._requests).length > 0
  }

  get shouldFetch() {
    return this.auth.isAuthenticated
  }

  initialize() {
    this.fetchInfo()
    this.infoReaction = reaction(
      () => this.shouldFetch,
      (shouldFetch) => {
        if (shouldFetch) {
          this.fetchInfo()
          this.fetchConnections()
        }
      }
    )

    this.versionReaction = reaction(
      () => this.info,
      async (info) => {
        if (info) {
          if (info.version !== process.env.REACT_APP_VERSION) {
            if (
              (
                await swal.fire(
                  popUpAlert(
                    'Your client version needs to be updated. Reload now?',
                    'Yes'
                  )
                )
              ).isConfirmed
            ) {
              window.location.reload(true)
            }
          }
        }
      }
    )

    this.dataDogReaction = reaction(
      () => ({
        env: this.info.env,
        version: this.info.version,
        user: this.info.user,
      }),
      ({ env, version, user }) => {
        if (env && version) {
          datadogRum.init({
            applicationId: '5bec9ae7-6f39-4fa1-a226-e8c19e7c7176',
            clientToken: 'pubb38ccb93e4b931d35e621de0957c65d8',
            site: 'us5.datadoghq.com',
            service: 'bdp---boss',
            env,
            version,
            sampleRate: 100,
            replaySampleRate: 100,
            trackInteractions: true,
          })
          if (user) {
            const datadogUser = {
              email: user.email,
              name: user.username,
              id: user.username,
            }
            datadogRum.setUser(datadogUser)
            datadogRum.startSessionReplayRecording()
          } else {
            try {
              datadogRum.stopSessionReplayRecording()
            } catch (err) {
              // no session recording happening
            }
          }
        }
      }
    )
  }
}

decorate(AppStore, {
  referrer: observable,
  path: computed,
  url: computed,
  setReferrer: action,
  info: observable,
  isLoginPage: computed,
  isSchedulePage: computed,
  isGamePage: computed,
  gamePk: computed,
  eventId: computed,

  _requests: observable,
  isLoading: computed,
  connections: observable,
  connectionMap: computed,

  set: action,
  notificationType: observable,
  customMessage: observable,
})
